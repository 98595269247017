@value xs, sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value yellow, black_r, black_g, black_b, white, lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointXsUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sellingItemImageOverlay from "@/modules/styling/zIndex.module.css";

@layer components {
  .soldText {
    color: yellow;
  }

  .boostedText {
    color: white;
    padding: 0 sm;
    text-align: center;
  }

  .imageOverlay {
    position: absolute;
    inset: 0;
    display: flex;
    z-index: sellingItemImageOverlay;
    align-items: center;
    justify-content: center;
    background-color: rgba(black_r, black_g, black_b, 0.3);
    object-fit: cover;
  }

  .likesWrapper {
    position: absolute;
    bottom: sm;
    left: sm;
    z-index: sellingItemImageOverlay;
    display: flex;
    align-items: center;
    color: white;
  }

  .likesNumber {
    color: white;
    margin-left: xs;
  }

  .image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .interactiveImageWrapper {
    cursor: pointer;

    &:hover {
      &::after {
        background: rgba(black_r, black_g, black_b, 0.3);
      }
    }
  }

  .imageWrapper {
    position: relative;
    background-color: lightGrey2;
    min-height: 90px;
    min-width: 90px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      background: linear-gradient(to bottom, rgba(black_r, black_g, black_b, 0), rgba(black_r, black_g, black_b, 0.3));
    }

    @media breakpointXsUp {
      min-height: 128px;
      min-width: 128px;
    }
  }
}
