@value lightGrey2, white, black_r, black_g, black_b, yellow from "@depop/web-ui-kit/theme/default/colors.module.css";
@value md, sm, xs from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointLgUp, breakpointSmUp, breakpointSmDown, breakpointXsUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sellingItemImageOverlay from "@/modules/styling/zIndex.module.css";

@layer components {
    .container {
        display: flex;
        flex-direction: column;
        padding: md 0;
        border-bottom: 1px solid lightGrey2;

        @media breakpointLgUp {
            flex-direction: row;
            justify-content: space-between;
            padding: md 0;
        }

    }

    .listingRow {
        display: grid;
        grid-template-columns: 120px 1fr;
        column-gap: sm;
        flex: 1;

        @media breakpointXsUp {
            grid-template-columns: 152px 1fr auto;
            column-gap: md;
            flex: 1 1 60%;
        }
    }

    .listingRowCheckboxes {
        grid-template-columns: auto 90px 1fr;

        @media breakpointXsUp {
            grid-template-columns: auto 152px 1fr auto;
        }
    }

    .checkboxContainer {
        display: flex;
        align-items: center;
        margin-left: sm;
    }

    .contentWrapper {
        overflow: hidden;
        position: relative;
    }

    .row {
        display: flex;
        align-items: center;
    }

    .category {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .sizeWrapper {
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
    }

    .dotDivider {
        content: '';
        display: block;
        border-radius: 50%;
        min-height: 6px;
        min-width: 6px;
        margin: 0 sm;
        background: lightGrey2;
    }

    .description {
        display: none;

        @media breakpointXsUp {
            display: block;
            margin-bottom: xs;
        }
    }

    .overflowText {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .priceContainer {
        display: flex;
        align-items: center;
        margin-bottom: sm;
    }

    .saleIcon {
        color: yellow;
        margin-left: -4px;
    }

    .discountedPrice {
        text-decoration: line-through;
        display: inline-block;
        padding-right: xs;
        margin-right: sm;
    }

    .mobileInsights {
        display: flex;
        overflow-x: scroll;
        width: 100%;
        justify-content: space-between;

        @media breakpointSmUp {
            display: none;
        }
    }

    .desktopInsights {
        display: flex;
        justify-content: flex-start;
        overflow-x: scroll;
        width: 100%;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        @media breakpointSmDown {
            display: none;
        }
    }

    .desktopInsights::-webkit-scrollbar {
        display: none;
    }

    .ctaContainer {
        align-items: center;
        display: flex;
        gap: sm;
        margin-top: md;
        
        button {
            min-width: 150px;
        }

        @media breakpointLgUp {
            margin-top: 0;
        }
    }

    .editButton{
        max-width: 50%;
    }
}