@value black, midGrey, green, lightGrey1 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value lg, md, sm, xs from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp, breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
    .productInsightsWrapper {
        display: inline-flex;
        overflow-x: scroll;
        -ms-overflow-style: none;

        /* IE and Edge */
        scrollbar-width: none;

        /* Firefox */
        margin-top: 0;
        gap: 0;
        justify-content: space-around;
        padding-top: md;
        padding-bottom: sm;
        width: 100%;


        @media breakpointMdUp {
            margin-top: lg;
            gap: 24px;
            justify-content: unset;

        }
    }

    .productInsightsWrapper::-webkit-scrollbar {
        display: none;
    }

    .insightGroup {
        display: flex;
        align-items: center;
        flex-direction: column;

        @media breakpointSmUp {
            flex-direction: row;
        }
    }

    .productInsight {
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        flex-direction: column;
        min-width: 50px;

        @media breakpointSmUp {
            flex-direction: row;
            min-width: auto;
        }
    }

    .insightText {
        color: black;
        margin-left: 0;

        @media breakpointSmUp {
            margin-left: sm;
        }
    }

    .boostViewsText {
        color: black;
        margin: 0;

        @media breakpointSmUp {
            margin: 0 sm;
        }
    }

    .mobileViewsText {
        display: block;
        color: black;
        margin-bottom: xs;

        @media breakpointSmUp {
            display: none;
        }
    }

    .boostGreyText {
        color: midGrey;
        margin-left: 0;

        @media breakpointSmUp {
            margin-left: sm;
        }
    }

    .boostingPill {
        border-radius: xs;
        padding: 2px xs;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .boostingIncrement {
        background-color: #d6f4c8;
        color: green;
    }

    .boostingFlat {
        background-color: lightGrey1;
        color: black;
    }

    .boostToSeeText {
        background-color: lightGrey1;
        color: midGrey;
        margin-left: 0;
        align-items: center;

        @media breakpointSmUp {
            margin-left: sm;
        }
    }

    .alignedIcon {
        vertical-align: middle;
    }
}